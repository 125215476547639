import React from "react"
import { Button, Card } from "react-bootstrap"

export default function AdvertisementCard({ node }) {
  return (
    <Card className="bg-warning ">
      <Card.Body>
        <Card.Title className="h4 font-weight-bold">
          {node.frontmatter.title}
        </Card.Title>
        <Card.Text className="mb-4">{node.frontmatter.description}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button
          variant="danger"
          size="sm"
          className="float-right stretched-link"
          href={node.frontmatter.source_url}
        >
          Show details
        </Button>
      </Card.Footer>
    </Card>
  )
}
