import React from "react"
import { Button, Card } from "react-bootstrap"
import formatGenderIcon from "../utils/utils"

export default function ProductCard({ node }) {
  function renderLocationLink() {
    if (!node.frontmatter.location) return

    return (
      "/hospitals/private/" +
      node.frontmatter.location.toLowerCase().split(" ").join("-")
    )
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title className="h4 font-weight-bold">
          {node.frontmatter.title} {formatGenderIcon(node.frontmatter.gender)}
        </Card.Title>
        <Card.Text className="mb-4">{node.frontmatter.description}</Card.Text>
        <div className="d-flex justify-content-between">
          <p className="h2 align-self-end">
            {node.frontmatter.price === 0
              ? "Free"
              : node.frontmatter.price.toLocaleString("en-MY", {
                  style: "currency",
                  currency: "MYR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
          </p>
        </div>
      </Card.Body>
      <Card.Footer>
        <small>
          <a href={renderLocationLink()}>{node.frontmatter.location}</a>
        </small>
        <Button
          variant="primary"
          size="sm"
          className="float-right"
          href={node.fields.slug}
        >
          Show details
        </Button>
      </Card.Footer>
    </Card>
  )
}
