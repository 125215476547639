import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Navbar,
  ToggleButton,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductCard from "../components/productCard"
import AdvertisementCard from "../components/advertisementCard"
import AdBanner from "../components/adBanner"
import formatGenderIcon from "../utils/utils"

export default function Home({ data }) {
  const [radioValue, setRadioValue] = useState("Male")
  const [centerValue, setCenterValue] = useState("All")
  const [locationValue, setLocationValue] = useState("Wilayah Persekutuan")

  const genderList = ["Male", "Female"]

  function loadMedicalCenters() {
    return data.allMarkdownRemark.edges.map(pkg => {
      if (
        pkg.node.frontmatter.state === locationValue ||
        locationValue === "All"
      )
        return pkg.node.frontmatter.location
    })
  }
  function loadStates() {
    return data.allMarkdownRemark.edges.map(pkg => {
      return pkg.node.frontmatter.state
    })
  }

  let centers = [...new Set(loadMedicalCenters())].sort()
  const centerList = ["All", ...centers]

  let stateList = [...new Set(loadStates())].sort()

  function loadAdvertisement(packages) {
    let loadedPackages = packages.filter(pkg => {
      return pkg.node.frontmatter.type === "ads"
    })
    return loadedPackages
  }

  function loadPackages(packages) {
    let loadedPackages = packages.filter(pkg => {
      return pkg.node.frontmatter.type === null
    })
    loadedPackages = loadedPackages.filter(pkg => {
      return (
        pkg.node.frontmatter.gender === radioValue ||
        pkg.node.frontmatter.gender === "All"
      )
    })
    if (centerValue !== "All") {
      loadedPackages = loadedPackages.filter(pkg => {
        return pkg.node.frontmatter.location === centerValue
      })
    }
    if (locationValue !== "All") {
      loadedPackages = loadedPackages.filter(pkg => {
        return pkg.node.frontmatter.state === locationValue
      })
    }
    return loadedPackages
  }

  let loadedPackages = loadPackages(data.allMarkdownRemark.edges)

  return (
    <Layout>
      <SEO
        title={"Best full medical check up packages"}
        description={data.site.siteMetadata.description}
      />
      <Container className="mb-5">
        <Row className="justify-content-center">
          <div className="col-md-4">
            <Card className="text-center">
              <Card.Body>
                <Card.Text>Choose packages for</Card.Text>
                <ButtonGroup toggle>
                  {genderList.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="light"
                      name="radio"
                      value={radio}
                      checked={radioValue === radio}
                      onChange={e => setRadioValue(radio)}
                    >
                      {formatGenderIcon(radio)}
                      {" " + radio}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="text-center">
              <Card.Body>
                <Card.Text>Choose state</Card.Text>
                <DropdownButton
                  as={ButtonGroup}
                  title={locationValue}
                  id="bg-nested-dropdown"
                  variant="light"
                  className="button-block"
                >
                  {stateList.map((radio, idx) => (
                    <Dropdown.Item
                      eventKey={idx}
                      variant="light"
                      value={radio}
                      onClick={e => {
                        setLocationValue(radio)
                        setCenterValue("All")
                      }}
                    >
                      {radio}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="text-center">
              <Card.Body>
                <Card.Text>Choose medical center</Card.Text>
                <DropdownButton
                  as={ButtonGroup}
                  title={centerValue}
                  id="bg-nested-dropdown"
                  variant="light"
                  className="button-block"
                >
                  {centerList.map((radio, idx) => (
                    <Dropdown.Item
                      eventKey={idx}
                      variant="light"
                      value={radio}
                      onClick={e => {
                        setCenterValue(radio)
                      }}
                    >
                      {radio}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row className="justify-content-center">
          <AdBanner />
        </Row>
      </Container>
      <Container>
        <div className="col-md-12">
          {" "}
          {loadedPackages.slice(0, 3).map(({ node }, index) => (
            <div className="col-md-12 mb-5" key={node.id}>
              <ProductCard node={node} />
            </div>
          ))}
          {loadAdvertisement(data.allMarkdownRemark.edges).map(({ node }) => (
            <div className="col-md-12 mb-5" key={node.id}>
              <AdvertisementCard node={node} />
            </div>
          ))}
          {loadedPackages.slice(3).map(({ node }, index) => (
            <div className="col-md-12 mb-5" key={node.id}>
              <ProductCard node={node} />
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            type
            price
            description
            location
            state
            source_url
            gender
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
