import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function formatGender(gender) {
  switch (gender) {
    case "Male":
      return "text-info"
    case "Female":
      return "text-danger"
    default:
      return "text-warning"
  }
}

export default function formatGenderIcon(gender) {
  switch (gender) {
    case "Male":
      return (
        <FontAwesomeIcon
          icon="male"
          size="lg"
          className={formatGender(gender)}
        />
      )
    case "Female":
      return (
        <FontAwesomeIcon
          icon="female"
          size="lg"
          className={formatGender(gender)}
        />
      )
    default:
      return (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon="male"
            size="lg"
            className={formatGender("Male")}
          />
          <FontAwesomeIcon
            icon="female"
            size="lg"
            transform="shrink-2 right-7"
            className={formatGender("Female")}
          />
        </span>
      )
  }
}
